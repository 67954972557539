import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { ButtonGroup, Button, ButtonColor, Theme, Icon } from '@class101/ui';
import { PlayGroundContainer } from '../docsComponents';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "buttongroup"
    }}>{`ButtonGroup`}</h1>
    <p>{`Button 컴포넌트를 가로나 세로로 묶을 때 사용합니다.`}<br parentName="p"></br>{`
`}{`Button 컴포넌트의 default 컬러만 지원합니다.`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={ButtonGroup} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<PlayGroundContainer>\n  <ButtonGroup>\n    <Button>Click me</Button>\n    <Button disabled>Click me</Button>\n    <Button loading>Click me</Button>\n    <Button leftIcon={<Icon.Bell />}>Click me</Button>\n  </ButtonGroup>\n</PlayGroundContainer>\n<PlayGroundContainer theme={Theme.dark}>\n  <ButtonGroup theme={Theme.dark}>\n    <Button>Click me</Button>\n    <Button disabled>Click me</Button>\n    <Button loading>Click me</Button>\n    <Button leftIcon={<Icon.Bell />}>Click me</Button>\n  </ButtonGroup>\n</PlayGroundContainer>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ButtonGroup,
      Button,
      ButtonColor,
      Theme,
      Icon,
      PlayGroundContainer,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <PlayGroundContainer mdxType="PlayGroundContainer">
    <ButtonGroup mdxType="ButtonGroup">
      <Button mdxType="Button">Click me</Button>
      <Button disabled mdxType="Button">Click me</Button>
      <Button loading mdxType="Button">Click me</Button>
      <Button leftIcon={<Icon.Bell />} mdxType="Button">Click me</Button>
    </ButtonGroup>
  </PlayGroundContainer>
  <PlayGroundContainer theme={Theme.dark} mdxType="PlayGroundContainer">
    <ButtonGroup theme={Theme.dark} mdxType="ButtonGroup">
      <Button mdxType="Button">Click me</Button>
      <Button disabled mdxType="Button">Click me</Button>
      <Button loading mdxType="Button">Click me</Button>
      <Button leftIcon={<Icon.Bell />} mdxType="Button">Click me</Button>
    </ButtonGroup>
  </PlayGroundContainer>
    </Playground>
    <h2 {...{
      "id": "fill"
    }}>{`Fill`}</h2>
    <Playground __position={2} __code={'<PlayGroundContainer>\n  <ButtonGroup fill>\n    <Button>Click me</Button>\n    <Button>Click me</Button>\n    <Button>Click me</Button>\n  </ButtonGroup>\n</PlayGroundContainer>\n<PlayGroundContainer>\n  <ButtonGroup fill>\n    <Button leftIcon={<Icon.Edit />}>수정하기</Button>\n    <Button leftIcon={<Icon.Trash />}>삭제하기</Button>\n    <Button leftIcon={<Icon.Link />}>상세 보기</Button>\n  </ButtonGroup>\n</PlayGroundContainer>\n<PlayGroundContainer theme={Theme.dark}>\n  <ButtonGroup theme={Theme.dark} fill>\n    <Button>Click me</Button>\n    <Button>Click me</Button>\n    <Button>Click me</Button>\n  </ButtonGroup>\n</PlayGroundContainer>\n<PlayGroundContainer theme={Theme.dark}>\n  <ButtonGroup theme={Theme.dark} fill>\n    <Button leftIcon={<Icon.Edit />}>수정하기</Button>\n    <Button leftIcon={<Icon.Trash />}>삭제하기</Button>\n    <Button leftIcon={<Icon.Link />}>상세 보기</Button>\n  </ButtonGroup>\n</PlayGroundContainer>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ButtonGroup,
      Button,
      ButtonColor,
      Theme,
      Icon,
      PlayGroundContainer,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <PlayGroundContainer mdxType="PlayGroundContainer">
    <ButtonGroup fill mdxType="ButtonGroup">
      <Button mdxType="Button">Click me</Button>
      <Button mdxType="Button">Click me</Button>
      <Button mdxType="Button">Click me</Button>
    </ButtonGroup>
  </PlayGroundContainer>
  <PlayGroundContainer mdxType="PlayGroundContainer">
    <ButtonGroup fill mdxType="ButtonGroup">
      <Button leftIcon={<Icon.Edit />} mdxType="Button">수정하기</Button>
      <Button leftIcon={<Icon.Trash />} mdxType="Button">삭제하기</Button>
      <Button leftIcon={<Icon.Link />} mdxType="Button">상세 보기</Button>
    </ButtonGroup>
  </PlayGroundContainer>
  <PlayGroundContainer theme={Theme.dark} mdxType="PlayGroundContainer">
    <ButtonGroup theme={Theme.dark} fill mdxType="ButtonGroup">
      <Button mdxType="Button">Click me</Button>
      <Button mdxType="Button">Click me</Button>
      <Button mdxType="Button">Click me</Button>
    </ButtonGroup>
  </PlayGroundContainer>
  <PlayGroundContainer theme={Theme.dark} mdxType="PlayGroundContainer">
    <ButtonGroup theme={Theme.dark} fill mdxType="ButtonGroup">
      <Button leftIcon={<Icon.Edit />} mdxType="Button">수정하기</Button>
      <Button leftIcon={<Icon.Trash />} mdxType="Button">삭제하기</Button>
      <Button leftIcon={<Icon.Link />} mdxType="Button">상세 보기</Button>
    </ButtonGroup>
  </PlayGroundContainer>
    </Playground>
    <h2 {...{
      "id": "vertical"
    }}>{`Vertical`}</h2>
    <Playground __position={3} __code={'<PlayGroundContainer>\n  <ButtonGroup vertical>\n    <Button>Click me</Button>\n    <Button>Click me</Button>\n    <Button>Click me</Button>\n  </ButtonGroup>\n</PlayGroundContainer>\n<PlayGroundContainer theme={Theme.dark}>\n  <ButtonGroup theme={Theme.dark} vertical>\n    <Button>Click me</Button>\n    <Button>Click me</Button>\n    <Button>Click me</Button>\n  </ButtonGroup>\n</PlayGroundContainer>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ButtonGroup,
      Button,
      ButtonColor,
      Theme,
      Icon,
      PlayGroundContainer,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <PlayGroundContainer mdxType="PlayGroundContainer">
    <ButtonGroup vertical mdxType="ButtonGroup">
      <Button mdxType="Button">Click me</Button>
      <Button mdxType="Button">Click me</Button>
      <Button mdxType="Button">Click me</Button>
    </ButtonGroup>
  </PlayGroundContainer>
  <PlayGroundContainer theme={Theme.dark} mdxType="PlayGroundContainer">
    <ButtonGroup theme={Theme.dark} vertical mdxType="ButtonGroup">
      <Button mdxType="Button">Click me</Button>
      <Button mdxType="Button">Click me</Button>
      <Button mdxType="Button">Click me</Button>
    </ButtonGroup>
  </PlayGroundContainer>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      